import * as React from 'react'
import { Link } from 'gatsby'
import { 
    
 } from './project.module.css'
import {
    container
} from './layout.module.css'

const Project = ({ title, project_link, children }) => {
  return (
    <div className={container}>
        <h3>{title}</h3>
        <a href={project_link} target="_blank">Link to project</a>
        {children}
    </div>
  )
}
export default Project