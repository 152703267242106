import * as React from 'react'
import Layout from '../components/layout'
import Project from '../components/project'

const ProjectsPage = () => {
  return (
    <Layout pageSentence="Check out some of my" pageTitle="Projects" active="projects">
      <Project title="Change in COVID-19 Misinformation Over Time" project_link="https://github.com/VangoCode/Change-In-Misinformation-over-COVID-19-Pandemic">
        <p>This is my first project.</p>
      </Project>
      
      <Project title="Cat Breed Classifier" project_link="https://github.com/VangoCode/Cat-Breed-Classifier">
        <p>This is my second project.</p>
      </Project>

      <Project title="ShopGreen" project_link="https://github.com/elsiezhu/ShopGreen-UofTHacks2022">
        <p>This is my third project.</p>
      </Project>
    </Layout>
  )
}

export default ProjectsPage